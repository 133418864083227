<template>
    <div>
		<!-- <div :class="'exportStatus ' + ((export_status.data.progress !== 0) && 'active')">
			<a-alert
				class="alert"
				message="您当前存在正在导出的任务"
				:description="export_status.data.type"
				show-icon
			>
				<template #icon>
					<a-progress :width="40" class="progress" type="circle" :percent="export_status.data.progress" />
				</template>
			</a-alert>
		</div> -->
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['out_link','status']"
            ref="list"
        >
            <template slot="out_link" slot-scope="data">
				<div style="position: relative;">
					<div class="num_text">{{ data.record.complete || 0 }}/{{ data.record.total || 0 }}</div>
					<a-progress :percent="data.record.progress" size="small" :status="getProgressState(data.record.status)" ></a-progress>
				</div>
				<div>
					<a target="_blank" :href="data.text" v-if="data.text">{{getFileName(data.text,data.record.type)}}</a>
					<span v-else style="color:#999999;">{{ data.record.type }}</span>
				</div>
			</template>
			<template slot="status" slot-scope="data">
				<!-- 同一时间只会有一条报表正在导出中 -->
				<a-tag :color="(data.text <= 0) ? config.color.error : config.color.primary">{{getDataStatusValue(data.record.status)}}</a-tag>
			</template>
        </TableList>
    </div>
</template>

<script>
import { preExportList,getExportProgress,getExport } from "@/api/export";
import TableList from "@/components/TableList";
import { Progress } from 'ant-design-vue'

let status_list = [
	{
		key:-2,
		value:'已失败'
	},
	{
		key:-1,
		value:'已过期'
	},
	{
		key:0,
		value:'未开始'
	},
	{
		key:1,
		value:'已完成'
	},
	{
		key:2,
		value:'进行中', // 进行中不进行实际显示，仅在此处对开发者作告知功能，实际上“进行中”状态下“状态”一列以进度条形式展现
	},
];
const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "报表类型名称",
		width:170,
        dataIndex: "type",
    },
	{
        title: "任务状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
	},
	{
        title: "任务进度",
        dataIndex: "out_link",
		width:300,
        scopedSlots: {
            customRender: "out_link"
        }
    },
	{
        title: "任务耗时",
        dataIndex: "duration",
    },
	{
        title: "开始时间",
        dataIndex: "start_time",
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "完成时间",
        dataIndex: "update_time",
    },
];


export default {
    name: "Index",
    components: {
        [Progress.name]:Progress,
        TableList,
    },
    data() {
        return {
			config:this.$config,
			get_table_list: preExportList,
			columns,
			status_list,
			export_status:{
				interval:null, // 为“报表导出状态”服务的定时器(s)，每三秒获取一次当前导出状态
				data:{
					type:'',
					progress:0
				}, // 当前导出的表格相关信息
			},
			last_data:null, // 上一条正在进行的导出任务，用于判断是否有导出任务被完成，如果有任务完成则再次请求当前列表 @type Object
        };
    },


    async created() {
		this.get_export_status();
    },
	destroyed() {
		// 退出页面时清除定时器
		clearInterval(this.export_status.interval)
	},
    methods: {
		// 获取当前正在导出的报表进度及该报表相关信息
        get_export_status(){
			this.export_status.interval = setInterval(()=>{
				clearInterval(this.export_status.interval); // 暂停定时器
				getExportProgress().then(res=>{
					this.get_export_status();
					let data = res.data.data;
					// null 代表两种情况，一种是上一条导出任务被完成了，还有一种情况则是自始至终没有任何导出任务
					if(data === null){
						data = {
							type:'',
							progress:0, // 赋予基础属性避免组件报错
						}
						/**
						 * 如果上一条任务不为空则代表有任务被完成了，刷新当前列表
						 * 但是有一点需要注意，当获取到的进度只有0时进度条也是不会显示出来的
						 * 在进度条显示不出的情况下我是不会主动请求列表的，需要用户自己手动刷新页面
						 */
						if(this.last_data !== null){
							this.$refs.list.get_list();
						}
						this.last_data = null;
					}else{
						//多条数据运行时，避免下一条任务已开始，上条任务还未结束
						this.getExportCompleteData()
						
						this.last_data = data;
					}
					//this.export_status.data = data;
				}).catch(res=>{
					this.get_export_status();
				})
			},3000)
		},

		//读取指定数据的导出信息
		getExportCompleteData(id){
			this.$refs.list.getListDataProgress().then(res=>{
				if(res.ids.length > 0){
					getExport({
						data:{
							ids:res.ids
						}
					}).then(res=>{
						this.$refs.list.getNowData(res.data.data);
					})
				}
			})
		},

		//处理导出数据名称
		getFileName(url,fileName) {
			let name = "";
			if (url !== null && url !== "") {
				name = url.substring(url.lastIndexOf("/") + 1);
			} else {
				name = fileName;
			}
			return name;
		},

		//导出下载列表使用当前方法，更新进度条和时间信息
		getProgressState(status){
			let statusName = 'normal'
			if(status == -1 || status == -2){
				statusName = 'exception'
			}else if(status == 1){
				statusName = 'success'
			}else if(status == 2){
				statusName = 'active'
			}
			return statusName
		},

		//处理任务状态
		getDataStatusValue(status){
			for(let i = 0; i < this.status_list.length; i++){
				if(this.status_list[i].key == status){
					return this.status_list[i].value
				}
			}
		},
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.exportStatus{
	transition:all 0.2s ease;
	height: 0;
	overflow: hidden;
	.alert{
		padding-left: 75px;
	}
}
.active{
	height: 85px;
	margin:15px;
	margin-bottom: -8px;
}
.num_text{
	zoom:0.8;
	position: absolute;
	width:100%;
	text-align: center;
	left:-26px;
	top:-8px;
	z-index:1;
}
</style>